/* eslint-disable */

export const remittanceMixin = {
  methods: {
    remittanceTimeRemaining(remittanceProcessedTime) {
      //This returns the time remaining before a new remittance can be submitted
      const now = new Date()
      const remittanceTime = new Date(remittanceProcessedTime)
      const diffInMs = new Date(remittanceTime.getTime() + 5 * 60000) - now;
      const diffInMinutes = Math.ceil(diffInMs / (1000 * 60));
      return diffInMinutes == 0 ? 'less than 1' : diffInMinutes

    }
  }
}
