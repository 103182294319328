<template>
  <b-modal
    class="bmodal"
    size="lg"
    hide-footer
    id="addSummaryModal"
    ref="modalAddSummary"
    :cancel-title="i18n['add-summary'].tcCancel"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action to utilize v-html-->
      <h5 class="modal-title">{{ i18n['add-summary'].tcTransactionSummary }} <span v-html="modalTitle"></span></h5>
      <b-button size="sm" class="close" aria-label="Close" style="background:none" @click="close()">
        x
      </b-button>
    </template>
    <div class="formdiv">
      <b-form v-if="!multicamp">
        <b-row class="srow" v-for="(item, idx) in transactionSummary(multicamp)" :key="idx">
          <!-- <b-col v-if="multicamp" sm="7">{{ item.camp_name }}</b-col> -->
          <b-col sm="7">{{ translations[item.group] }} - {{ translations[item.category] }}</b-col>
          <b-col sm="4" class="aright">{{ item.amount | toCurrency }}</b-col>
        </b-row>
        <b-row class="totrow">
          <b-col sm="7" class="bold">{{ i18n['add-summary'].tcTotal }}</b-col>
          <b-col sm="4" class="bold tot">{{ amountTally | toCurrency }}</b-col>
        </b-row>
        <div>
          <b-row class="srow" v-for="(pitem, pidx) in presentationSummary" :key="pidx + `p`">
            <b-col sm="7">{{ translations[pitem.group] }} - {{ translations[pitem.category] }}</b-col>
            <b-col sm="4" class="aright">{{ pitem.amount }}</b-col>
          </b-row>
        </div>
      </b-form>
      <b-form v-if="multicamp">
        <b-row class="">
          <b-col sm="6" class="bold"></b-col>
          <b-col sm="4" class="bold tot">Amount</b-col>
          <b-col v-if="countTally > 0" sm="2" class="bold tot">Count</b-col>
        </b-row>
        <b-row class="srow" v-for="(item, idx) in transactionSummary(multicamp)" :key="idx">
          <b-col sm="6">{{ item.camp_name }}</b-col>
          <b-col sm="4" class="aright">{{ item.amount | toCurrency }}</b-col>
          <b-col v-if="item.count > 0" sm="2" class="aright">{{ item.count }}</b-col>
        </b-row>
        <b-row class="totrow">
          <b-col sm="6" class="bold">{{ i18n['add-summary'].tcTotal }}</b-col>
          <b-col sm="4" class="bold tot">{{ amountTally | toCurrency }}</b-col>
          <b-col v-if="countTally > 0" sm="2" class="bold tot">{{ countTally }}</b-col>
        </b-row>
      </b-form>
    </div>
    <b-row>
      <div class="xbuttons">
        <b-button variant="primary" class="btn btn-primary float-left" @click="handleClickCancel">{{
          i18n['add-summary'].tcCancel
        }}</b-button>
        <b-button
          variant="secondary"
          class="btn btn-secondary float-right mr20"
          @click="handleSaveClick($event)"
          :disabled="remittanceTimeRemaining(remittanceProcessedTime) > 0"
        >
          {{ i18n['add-summary'].tcProcess }}</b-button
        >
      </div>
    </b-row>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapState } from 'vuex'
import { numericMixin } from '@/mixins/numericMixin'
import { remittanceMixin } from '@/mixins/remittanceMixin'

export default {
  name: 'AddSummary',
  mixins: [numericMixin, remittanceMixin],
  props: {
    modalTitle: {
      default: '',
      type: String
    },
    multicamp: {
      default: false,
      type: Boolean
    },
    translations: {
      type: Object
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'add-summary': {
            tcCancel: 'Cancel',
            tcTotal: 'Total',
            tcProcess: 'Process',
            tcTransactionSummary: 'Transaction Summary'
          }
        }
      }
    }
  },
  data() {
    return {
      countTally: 0,
      noCloseOnBackdrop: true,
      activity: {}
    }
  },
  computed: {
    ...mapGetters({
      currentCamp: 'campModule/currentCamp',
      presentationSummary: 'remittanceModule/presentationSummary',
      transactionSummary: 'remittanceModule/transactionSummary'
    }),
    ...mapState({
      remittanceProcessedTime: state => state.remittanceModule.remittanceProcessedTime
    }),
    amountTally() {
      let transactionTotal = 0.0
      this.countTally = 0
      this.transactionSummary(this.multicamp).forEach(element => {
        transactionTotal += parseFloat(element.amount)
        this.countTally += parseInt(element.count) === 'NaN' ? 0 : parseInt(element.count)
      })
      return transactionTotal.toFixed(2)
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setPendingRemittanceTime: 'remittanceModule/setPendingRemittanceTime'
    }),
    closeModal() {
      this.$refs.modalAddSummary.hide()
    },
    handleClickCancel() {
      this.closeModal()
    },
    async handleSaveClick() {
      //set remittance pending timestamp so no other remittances can be submitted for at least 10 min from now.
      this.setPendingRemittanceTime()
      this.$emit('continueToPayment')
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: lightgrey !important;
}
i {
  font-size: 12px;
}

.row {
  line-height: 36px;
}

.mr20 {
  margin-right: 20px;
}

.srow {
  font-size: 15px;
}

.totrow {
  background-color: #003946;
  color: #ffffff;
  margin-bottom: 20px;
}

.bold {
  font-weight: 700;
}

.tot {
  font-size: 18px;
  text-align: right;
}

.aright {
  text-align: right;
}

.activity-input {
  margin-top: 2px;
  text-align: right;
}

.xbuttons {
  margin: 30px 20px 20px 20px;
  width: 100%;
}

.btn.btn-secondary {
  background-color: #fbc301;
}

.btn.btn-primary {
  background-color: #003946;
}

.btn.btn-primary:hover {
  background-color: #003946;
}

.btn.btn-secondary:hover {
  background-color: #fbc301;
  -color: #003946;
}

.modal-header {
  height: 63px;
}
</style>
